import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Oh fuck! It's a sequel gift; what a twist.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=eIPR4OSElEs"
      }}>{`🎵 You're one year older, one year wiser. Rock and roll star, queen, czar, and the kaiser. A room full of friends, a mouth full of cake, every present is for you and it feels pretty great. You're the girl of the hour, the VIP. You get the first slice of the P-I-E. But first blow out the candles... and make a wish. Put a smile on; it's your birthday bitch! 🎵`}</a></p>
    <p>{`I hope this will be the best birthday yet, my love! Last time we celebrated our birthdays we were just girlfriend and boyfriend, but now we're engaged! We're going to get meowied. I sometimes can't believe it. I'm so in love with you and can't wait to be your husband and you my wife.`}</p>
    <p>{`Also we should take a couple days off work and just play, like, a fuck-ton of Zelda.`}</p>
    <p className="signature">
	<span>&mdash;</span> Amir
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      